/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState, useEffect, useRef } from "react"
// import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
// import { StarIcon } from "@heroicons/react/solid"
// import DeliveryIcon from "../assets/icons/delivery.svg"
import {
  getAssetSubcriptionInventory,
  // getMyBookmarkSingleWithID,
  getSingleAsset,
  // getSingleAssetQty,
  getSingleAssetRelated,
  // getSingleProduct,
  // sessToken,
  subcribeAssetInventory,
  orderDigitalAsset as orderDigitalAssetCallback,
  trackVisits,
} from "../helpers/DrupalHelper"
// import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import Layout from "../components/Layout/Pages"
import { navigate } from "gatsby"
import ProductImageBox from "../components/ProductImageBox"
// import AssetsCard from "../components/Cards/AssestCard"
import RelatedProduct from "../components/RelatedProduct"
// import RelatedAssets from "../components/RelatedAssets"
import MetaUpdated from "../components/Elements/MetaUpdated"
import MetaDelivery from "../components/Elements/MetaDelivery"
import MetaDownload from "../components/Elements/MetaDownload"
import Separator from "../components/Elements/Separator"
import CircleAvatar from "../components/Elements/CircleAvatar"
import Container from "../components/Container"
import AddToCartBox from "../components/Elements/AddToCartBox"
import AddToWishlist from "../components/Elements/AddToWishlist"
import Tag from "../components/Elements/Tag"
import Tags from "../components/Elements/Tags"
import StateContext from "../context/state/StateContext"
import ProductAssetListing from "../components/ProductAssetListing"
import { Link } from "gatsby"
// import FloatingInput from "../components/Elements/Form/FloatingInput"
import Button from "../components/Elements/Button"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import Skeleton from "../components/Skeleton"
import NotificationContext from "../context/Notification/NotificationContext"
import Share from "../components/Elements/Share"
import { verifiedHost } from "../helpers/HostHelper"
import Modal from "../components/Modal/Modal"
import RequestAssetForm from "../components/Forms/RequestAssetForm"
import AccessDenied from "../components/AccessDenied"
const parse = require("html-react-parser")

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const StockStatus = props => {
  const { data } = props

  if (data < 1)
    return (
      <Tags>
        <Tag red>Out of Stock</Tag>
      </Tags>
    )
  if (data < 5)
    return (
      <Tags>
        <Tag yellow>Low Stock</Tag>
      </Tags>
    )
  return (
    <Tags>
      <Tag>In Stock</Tag>
    </Tags>
  )
}
const AssetsSingle = ({ pageContext, location }) => {
  const [serverData, setServerData] = useState(null)
  const [count, setCount] = useState(0)
  const [rData, setRData] = useState(null)
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const { userType } = useContext(AuthenticationContext)

  // const { myBookmarksID } = useContext(DataContext).data
  const { toggleNotification } = useContext(NotificationContext)
  // const [qtyData, setQtyData] = useState(null)
  const [inventorySubcribed, setInventorySubscribed] = useState(null)
  // const [emailInputVal, setEmailInputVal] = useState(currentUserData.email)
  const [open, setOpen] = useState(false)
  const [forbidden, setForbidden] = useState(false)
  const assets = serverData
  // const [selectedColor, setSelectedColor] = useState(product.colors[0])
  const { token } = useContext(StateContext).state
  // const increment = () => setCount(count + 1)
  // const decrement = () => setCount(count - 1)
  // const [following, setFollowing] = useState(false)
  const goBack = () => {
    // console.log("is back", window.PressedBack)
    window.PressedBack = true
  }
  useEffect(() => {
    window.addEventListener("popstate", goBack)
    // window.removeEventListener("popstate", () => {
    //   console.log("event removed");
    // });
    return () => {
      window.removeEventListener("popstate", goBack)
    }
  }, [])
  const onDownload = async id => {
    const rand = () => Math.floor(100000000000 + Math.random() * 900000000000)
    let query = `${rand()}${id}${rand()}`
    query = btoa(query)
    const url = `${verifiedHost}/api/download?q=${query}`
    window.open(url, "_blank").focus()
  }
  const orderDigitalAsset = async assetId => {
    let res = await orderDigitalAssetCallback(token, assetId)
    if (res.ok) {
      // navigate("/dashboard/my-orders")
      const result = await res.text()
      // console.log(result)
      // console.log("res.text()")
      // console.log(res)
      // console.log("res")

      if (result.includes("OK")) {
        // console.log('true')
        toggleNotification({
          content: `Thanks for ordering ${serverData.title}! Your download is starting now.`,
        })
        onDownload(assetId)
      } else {
        toggleNotification({
          content: `Downloading the latest version of ${serverData.title}`,
        })
        onDownload(assetId)
      }
    } else {
      // console.log(res)
      // console.log("res")
      toggleNotification({
        content: `There was an issue processing your digital order.`,
        error: true,
      })
    }
  }
  const callInventorySuscribe = async () => {
    let body = {
      user: currentUserData.i,
      email: currentUserData.email,
      asset: serverData.id,
    }
    let res = await subcribeAssetInventory(token, body)
    // console.log(res, res.json())
    if (res.status === 200) {
      toggleNotification({
        content: `${serverData.title} Inventory Successfully Subscribed`,
      })
      setInventorySubscribed(true)
    } else {
      toggleNotification({
        content: `Fail to subscribe ${serverData.title} inventory `,
        error: true,
      })
    }
  }
  // useEffect(async () => {
  //   console.log(pageContext.id, location)
  //   let array = location.pathname.split("/")
  //   if (array.length > 2) {
  //     const res = await getSingleAsset(token, parseInt(array[2]))
  //     if (res) {
  //       let nid = array[2]
  //       setServerData({ ...res, id: nid })

  //       let relatedData = await getSingleAssetRelated(token, nid)
  //       console.log("related", relatedData)
  //       setRData(relatedData)
  //       let data = await getSingleAssetQty(token, nid)
  //       // console.log(data)
  //       let iData = await getAssetSubcriptionInventory(token, nid)
  //       if (iData === "Not subscribed") setInventorySubscribed(false)
  //       else setInventorySubscribed(true)
  //       setQtyData(data)
  //       trackVisits(token, {
  //         type: "asset",
  //         asset: nid,
  //         user: currentUserData.i,
  //       })
  //     } else {
  //       navigate("/404")
  //     }
  //   } else {
  //     navigate("/404")
  //   }
  // }, [])
  useEffect(async () => {
    // console.log(pageContext.id, location)
    let array = location.pathname.split("/")
    setServerData(null)

    if (array.length > 2) {
      let res = await getSingleAsset(token, parseInt(array[2]))

      // console.log(res, 'ressy')
      if (res.status === 200) {
        // alert('test')
        res = await res.json()
        let nid = array[2]
        setServerData({ ...res, id: nid })

        // console.log(res, 'res')
        let relatedData = await getSingleAssetRelated(token, nid)
        // console.log("related", relatedData)
        setRData(relatedData)

        // console.log(relatedData, 'resRelated')
        // let data = await getSingleAssetQty(token, nid)
        // console.log(data)
        let iData = await getAssetSubcriptionInventory(token, nid)
        if (iData === "Not subscribed") setInventorySubscribed(false)
        else setInventorySubscribed(true)

        // console.log(data, 'resQty')

        // setQtyData(data)
        trackVisits(token, {
          type: "asset",
          asset: nid,
          user: currentUserData.i,
        })
      } else if (res.status === 403) {
        setForbidden(true)
      } else {
        navigate("/404")
      }
    } else {
      navigate("/404")
    }
  }, [location])
  useEffect(() => {
    if (serverData !== null) {
      // setFollowing(
      //   myBookmarksID["asset"]?.includes(serverData.id) ? true : false
      // )
      // getMyBookmarkSingleWithID(token, "asset", serverData.id).then(v => {
      //   // console.log(v)
      //   if (v.length) {
      //     setFollowing(true)
      //   } else {
      //     setFollowing(false)
      //   }
      // })
    }
  }, [serverData])
  const scrollRef = useRef()

  // console.log(serverData?.price, 'price')
  // console.log(serverData?.price)

  if (forbidden) {
    return <AccessDenied />
  }

  return (
    <Layout metaTitle={!serverData ? "Asset" : serverData.title} metaDesc={""}>
      <div className="bg-white">
        {serverData !== null ? (
          <>
            <Container>
              {/*<div className="max-w-2xl mx-auto pt-[30px] pb-[60px] md:py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">*/}
              <div
                className={`lg:grid lg:grid-cols-2 lg:gap-x-12 lg:items-start md:py-[50px] md:pt-${userType.brandEditor && "[90px]"
                  }`}
              >
                {/* Image gallery */}
                <ProductImageBox
                  isAsset={true}
                  images={serverData.cover}
                  name={serverData.title}
                  category={serverData.category}
                />
                {/* Product info */}
                <div className="mt-[30px] md:mt-10  sm:px-0 sm:mt-16 lg:mt-0 ">
                  <div className="flex items-end">
                    <h1 className="text-title-med-30 mr-3">{assets.title}</h1>
                    {serverData.date && serverData.date !== "" && (
                      <MetaUpdated text={`Last Updated ${serverData.date}`} />
                    )}
                  </div>

                  <div className="flex items-center mt-3">
                    <Link
                      className="mr-auto"
                      to={`/brand/${serverData.brand_id}`}
                    >
                      <CircleAvatar
                        image={serverData.brand_logo}
                        alt={serverData.brand_name}
                        text={serverData.brand_name}
                        md
                        border
                      />
                    </Link>
                    {serverData.type === "physical" ? (
                      <MetaDelivery large />
                    ) : (
                      <MetaDownload large />
                    )}
                  </div>
                  <Separator className="mt-4 mb-6" />
                  {/* <h2
                    className={`text-meta-med col-span-1 !leading-[23px] ${
                      serverData.type === "physical" && "mb-4"
                    } `}
                  >
                    {serverData.price === "0.00"
                      ? "FREE"
                      : `$ ${serverData.price}`}
                  </h2> */}

                  <div className="flex items-center justify-between">
                    {serverData?.type === "physical" && (
                      <StockStatus data={serverData.status.available_total} />
                    )}
                    {typeof serverData.price !== "undefined" &&
                      serverData.price !== null &&
                      serverData.price !== "0.00" ? (
                      <div className="mr-auto flex items-center">
                        <h2 className="price-label-lg">${serverData.price}</h2>
                        {/*<h3 className="ml-4 line-through price-label-lg-strike">
                          ${serverData.price}
                        </h3> */}
                      </div>
                    ) : (
                      <p className="font-medium">FREE!</p>
                    )}
                  </div>
                  {serverData?.description && (
                    <p className="text-reg-16 mt-6">
                      {parse(serverData.description)}
                    </p>
                  )}

                  {serverData.type === "physical" && (
                    <div className="mt-6">
                      <>
                        <>
                          {serverData?.status?.in_stock ? (
                            <AddToCartBox
                              assetData={serverData}
                              /* @TODO: remove this superflous spread, favor assetData prop for additional nested required ops */
                              {...serverData}
                              qtyData={serverData.status}
                            />
                          ) : (
                            <div className="mt-3 md:p-5 md:bg-[#f7f7f7] md:rounded-[8px] md:border md:border-[#EBEBEB] flex items-center">
                              {inventorySubcribed ? (
                                <p className="text-reg-16 !leading-[20px] md:!leading-[30px] mb-[30px] md:mb-[15px]">
                                  <span className="text-med-16">
                                    We'll let you know when this item is back in
                                    stock.
                                  </span>{" "}
                                </p>
                              ) : (
                                <div>
                                  <h5 className="text-semibold-18 mb-[10px]">
                                    It'll be back soon.
                                  </h5>
                                  <p className="text-reg-16 !leading-[20px] md:!leading-[22px] ">
                                    <span className="">
                                      This item is currently out of stock,
                                    </span>{" "}
                                    but we can notify you when it's back.
                                  </p>
                                  <div className="relative w-full flex items-center flex-col md:flex-row md:space-x-[15px] pt-6">
                                    <div className="md:w-[220px]">
                                      <Button
                                        red
                                        full
                                        onClick={() => callInventorySuscribe()}
                                      >
                                        Notify Me
                                      </Button>
                                    </div>
                                    <AddToWishlist
                                      title={serverData.title}
                                      id={serverData.id}
                                      name={"asset"}
                                      isListed={serverData.bookmarked}
                                    />
                                    <Share
                                      title={serverData.title}
                                      id={serverData.id}
                                      name={"asset"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      </>
                    </div>
                  )}
                  {serverData.type === "digital" && (
                    <AddToCartBox
                      assetData={serverData}
                      /* @TODO: remove this superflous spread, favor assetData prop for additional nested required ops */
                      {...serverData}
                      qtyData={serverData.status}
                    />
                  )}
                  <div className="mt-[30px] md:mt-7">
                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        Last Ordered
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        {serverData?.status?.ordered_last ||
                          `You haven't previously ordered this item`}
                      </h2>
                    </div>
                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        SKU
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        {serverData.sku}
                      </h2>
                    </div>
                    {serverData.tags && serverData.tags?.length > 0 && (
                      <div className="flex items-start text-left grid grid-cols-3 mb-4">
                        <h2 className="text-meta-med col-span-1 !leading-[23px]">
                          Tags
                        </h2>
                        <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                          {serverData.tags.map((i, k) => (
                            <>
                              {i}
                              {k < serverData.tags.length - 1 && ", "}
                            </>
                          ))}
                        </h2>
                      </div>
                    )}
                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        Related Products
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        {serverData.products.map((i, k) => (
                          <Link
                            style={{
                              color: "red",
                              textDecoration: "underline",
                            }}
                            className="mr-auto"
                            to={`/product/${i.id}`}
                          >
                            <>{`${i.title}${k !== serverData.products.length - 1 ? "," : ""
                              } `}</>
                          </Link>
                        ))}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            {rData !== null && (
              <div className="bg-sections-alt">
                {
                  // {rData.related_tags &&
                  //   Array.isArray(rData.related_tags) &&
                  //   rData.related_tags.length > 0 && (
                  //     <div className="mt-10" ref={scrollRef}>
                  //       <Container>
                  //         <Separator className=" md:hidden mb-10" />
                  //         <ProductAssetListing
                  //           title={"Related Assets"}
                  //           assets={rData.related_tags}
                  //           refP={scrollRef?.current}
                  //         />
                  //         {/*<RelatedAssets
                  //     title={"Related Assets"}
                  //     products={rData.related_tags}
                  //   />*/}
                  //       </Container>
                  //     </div>
                  //   )}
                }
                {rData.related_brand &&
                  Array.isArray(rData.related_brand) &&
                  rData.related_brand.length > 0 && (
                    <div className="mt-10  bg-[#f7f7f7] pt-10 pb-[5rem]">
                      <Container>
                        <RelatedProduct
                          title={"More from this Brand"}
                          subtitle={`Explore more from ${serverData.brand_name}`}
                          products={rData.related_brand}
                        />
                      </Container>
                    </div>
                  )}
                {rData.related_category &&
                  Array.isArray(rData.related_category) &&
                  rData.related_category.length > 0 && (
                    <div className="pt-10 pb-[5rem]">
                      <Container>
                        <RelatedProduct
                          title={"More in this Category"}
                          subtitle={`Browse more in ${serverData.category}`}
                          products={rData.related_category}
                        />
                      </Container>
                    </div>
                  )}
              </div>
            )}
            {open && (
              <Modal
                isOpen={open}
                closeModal={() => setOpen(false)}
                title={"Request Asset"}
              >
                <RequestAssetForm data={serverData} id={serverData.id} />
              </Modal>
            )}
          </>
        ) : (
          <>
            <Container>
              {/*<div className="max-w-2xl mx-auto pt-[30px] pb-[60px] md:py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">*/}
              <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 lg:items-start md:py-[50px]">
                {/* Image gallery */}
                <div>
                  <Skeleton
                    dark
                    width={"90%"}
                    height={"400px"}
                    rounded={"10px"}
                  />
                  <div className="w-10 h-10 mt-4 mb-6">
                    <Skeleton
                      dark
                      width={"100px"}
                      height={"100px"}
                      rounded={"10px"}
                    />
                  </div>
                </div>
                {/* Product info */}
                <div className="mt-[30px] md:mt-10  sm:px-0 sm:mt-16 lg:mt-0 ">
                  <div className="flex items-end">
                    <h1 className="text-title-med-30 mr-3">
                      <Skeleton dark width={"400px"} height={"40px"} />
                    </h1>
                    {/* <Skeleton dark width={"200px"} height={"10px"} /> */}
                  </div>

                  <div className="flex items-center mt-3">
                    <div className="mr-4">
                      <CircleAvatar md border />
                    </div>
                    <Skeleton dark width={"100px"} height={"30px"} />
                  </div>
                  <Separator className="mt-4 mb-6" />

                  <div className="flex items-center">
                    <div className="mr-auto flex items-center">
                      <Skeleton dark width={"200px"} height={"30px"} />
                    </div>
                  </div>
                  <p className="text-reg-16 mt-6">
                    <Skeleton dark width={"100%"} height={"40px"} />
                  </p>

                  <div className="mt-6">
                    <Skeleton dark width={"100px"} height={"100px"} />
                  </div>
                  <div className="mt-[30px] md:mt-7">
                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        <Skeleton dark width={"100px"} height={"20px"} />
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        <Skeleton dark width={"300px"} height={"20px"} />
                      </h2>
                    </div>
                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        <Skeleton dark width={"100px"} height={"20px"} />
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        <Skeleton dark width={"300px"} height={"20px"} />
                      </h2>
                    </div>

                    <div className="flex items-start text-left grid grid-cols-3 mb-4">
                      <h2 className="text-meta-med col-span-1 !leading-[23px]">
                        <Skeleton dark width={"100px"} height={"20px"} />
                      </h2>
                      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
                        <Skeleton dark width={"200px"} height={"20px"} />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </>
        )}
      </div>
    </Layout>
  )
}
// export async function getServerData(context) {
//   // console.log(context)
//   let nid = context?.pageContext?.nid
//   // let id = 1
//   if (!nid) nid = Object.values(context.params)[0]
//   try {
//     const token = await sessToken()
//     const res = await getSingleAsset(token, parseInt(nid))
//     // console.log(res)

//     return {
//       props: { ...res, id: nid },
//     }
//   } catch (error) {
//     return {
//       status: 404,
//       headers: {},
//       props: {},
//     }
//   }
// }

export default AssetsSingle
